@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;1,400;1,500;1,700&display=swap");
@import "./colors";
@import "./react-phone-input";
@import "./common";
@import "./multiStepForm";
@import "./sidebar";
@import "./navbar";
@import "./dashboard";
@import "./multiStepForm";
@import "./header.scss";
@import "./getting-started";
@import "./users";
@import "./contractors";
@import "./react-select";
@import "./contactus";
@import "./onOff-switch";
@import "./footer";
@import "./animated-dots";
@import "./checklists";
@import "./react-date-picker";

*,
html,
body {
  font-family: "Poppins", sans-serif;
}

*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
